import {
  type Easing,
  type InputState,
  PointerTrait,
  Track,
  type Trait,
} from "@sv/elements/track";

export class DrawerTrack extends Track {
  public traits: Trait[] = [
    new PointerTrait(),
    {
      id: "drawer",
      input(track: DrawerTrack, inputState: InputState) {
        const openThresholdFixed = window.innerHeight / 2;
        const openThreshold = window.innerHeight - openThresholdFixed;

        if (track.position.y > openThreshold && !track.isOpen) {
          track.setOpen(true);
        }
        if (track.position.y < openThreshold && track.isOpen) {
          track.setOpen(false);
        }

        if (track.grabbing || track.scrolling || track.target) return;
        if (track.deltaVelocity.y >= 0) return;
        if (track.isStatic) return;

        const vel = Math.round(track.lastVelocity[track.currentAxis] * 10) / 10;
        const power = Math.round(vel / 15);

        if (power < 0) {
          track.minimize();
        } else if (power > 0) {
          track.open();
        } else {
          if (track.position.y > 400) {
            track.open();
          } else if (track.position.y > 40) {
            track.minimize();
          } else {
            track.close();
          }
        }
      },
    },
  ];

  transitionTime = 350;
  drag = 0.98;
  isOpen = false;

  declare contentheight?: number;

  get isStatic() {
    return !!this.contentheight;
  }

  constructor() {
    super();
    this.vertical = true;
  }

  static get properties() {
    return {
      ...Track.properties,
      contentheight: { type: Number, reflect: true },
    };
  }

  setOpen(value: boolean) {
    this.isOpen = value;

    // dely event to prevent jank
    if (value === true) {
      this.dispatchEvent(new Event("open", { bubbles: true }));
    } else {
      this.dispatchEvent(new Event("close", { bubbles: true }));
    }
  }

  open(ease: Easing = "linear") {
    this.acceleration.mul(0.25);
    this.inputForce.mul(0.125);
    this.setTarget(this.getToItemPosition(1), ease);
  }

  minimize(ease: Easing = "linear") {
    let height = 200;
    if (this.isStatic) {
      const value = this.getAttribute("contentheight");
      const valueInt = value ? +value : Number.NaN;
      const openedPosition = this.getToItemPosition(1);
      height = valueInt > openedPosition.y ? openedPosition.y : valueInt;
    }

    this.acceleration.mul(0.25);
    this.inputForce.mul(0.125);
    this.setTarget([0, height], ease);
  }

  close(ease: Easing = "linear") {
    this.acceleration.mul(0.25);
    this.inputForce.mul(0.125);
    this.setTarget([0, 30], ease);
  }
}

customElements.define("drawer-track", DrawerTrack);
