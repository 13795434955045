import { ClientOnly } from "#components";
import {
  defineComponent,
  effect,
  nextTick,
  onMounted,
  ref,
  useDetails,
  useLanguage,
  useListener,
  useRouter,
  useTranslations,
} from "#imports";
import "@sv/elements/blur";
import { Button } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import { navigate } from "package:/components/elements/Link";
import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { useHistory } from "package:/composables/useHistory";
import { getDeviceType } from "package:/composables/useResponsive";
import { ROUTE_VIEW } from "package:/utils";
import "@sv/elements/blur";
import type { ClassNameValue } from "tailwind-merge";
import "../elements/Drawer";
import type { DrawerTrack } from "../elements/Drawer";

/**
 * Displays a drawer on mobile and sidebar on desktop
 */
export const PageLayer = defineComponent(
  (
    props: {
      class?: ClassNameValue;
      variant?: string;
      open?: boolean;
      drawerDisabled?: boolean;
      drawerDynamicHeight?: boolean;
      // X to close was pressed
      onClose?: () => void;
      // Drawer was pushed down
      onCollapse?: () => void;
      // // when the drawer is pushed down
      // onCollapse: () => void;
      // when the drawer is pulled up
      onOpen?: () => void;
      showTitle?: boolean;
      showClose?: boolean;
    },
    { slots },
  ) => {
    const isDesktop = getDeviceType() === "desktop";
    const t = useTranslations();
    const open = ref(props.open ?? false);
    const drawer = ref<DrawerTrack>();
    const router = useRouter();
    const detailsData = useDetails();
    const scrollContainer = ref<HTMLDivElement>();
    const contentContainer = ref<HTMLDivElement>();
    const drawerHeight = ref<number>();
    const language = useLanguage();
    const historyState = useHistory(() => {
      const scrollPos = scrollContainer.value?.scrollTop;
      return {
        pageLayerScrollPosition: scrollPos,
      };
    });
    const mounted = ref(false);
    let timeout: NodeJS.Timeout;

    effect(() => {
      if (props.open === true) {
        open.value = true;
      }
    });

    effect(() => {
      const state = historyState.value;
      scrollContainer.value?.scrollTo(
        0,
        state?.replaced ? 0 : state?.pageLayerScrollPosition || 0,
      );
    });

    onMounted(() => {
      mounted.value = true;

      requestAnimationFrame(async () => {
        drawerHeight.value = contentContainer.value?.offsetHeight;

        await nextTick();
        // default state should always be half open
        drawer.value?.minimize();
      });
    });

    const updateView = () => {
      const query = router.currentRoute.value?.query;
      if (query.view === ROUTE_VIEW.DETAIL) {
        !drawer.value?.grabbing && drawer.value?.open();
      } else if (query.view === ROUTE_VIEW.PREVIEW) {
        !drawer.value?.grabbing && drawer.value?.minimize();
      }
    };
    router.afterEach(updateView);

    useListener(window, "map.move", () => {
      if (!props.drawerDisabled) {
        drawer.value?.close();
      }
    });

    return () => {
      return (
        <div class={props.class}>
          {/* Mobile view */}
          {!isDesktop && (
            <>
              {/* covers hole when overscroll on the bottom */}
              <div
                class={[
                  "pointer-events-none fixed bottom-0 left-0 h-[100px] w-full bg-[var(--color-bg)]",
                  open.value ? "opacity-100" : "opacity-0",
                ]}
              />

              <div
                class={[
                  "absolute top-0 bottom-0 left-0 w-full overflow-hidden pt-[50px]",
                  mounted.value ? "opacity-100" : "opacity-0",
                ]}
              >
                <MobileSafeArea class="h-full" bottom={false}>
                  <drawer-track
                    contentheight={
                      props.drawerDynamicHeight ? drawerHeight.value : undefined
                    }
                    ref={drawer}
                    class="block h-full w-full translate-y-0 touch-none transition-all"
                    onOpen={() => {
                      open.value = true;

                      // let the ui idle a bit
                      if (timeout) clearTimeout(timeout);
                      timeout = setTimeout(() => props.onOpen?.(), 160);
                    }}
                    onClose={() => {
                      open.value = false;
                      scrollContainer.value?.scrollTo(0, 0);

                      // let the ui idle a bit
                      if (timeout) clearTimeout(timeout);
                      timeout = setTimeout(() => props.onCollapse?.(), 160);
                    }}
                    onMove={(e) => {
                      if (props.drawerDisabled) e.preventDefault();

                      if (
                        scrollContainer.value &&
                        scrollContainer.value?.scrollTop > 10 &&
                        open.value === true
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onFormat={(e) => {
                      e.preventDefault();
                      updateView();
                    }}
                  >
                    <div class="h-[calc(100vh-169px)] w-full" />

                    <div class="pointer-events-auto relative rounded-t-lg bg-[var(--color-bg)]">
                      <div class="flex w-full justify-center py-3">
                        {!props.drawerDisabled && (
                          <div class="h-[2px] w-[40px] rounded-3xl bg-emerald-100" />
                        )}
                      </div>

                      <div
                        ref={scrollContainer}
                        data-scroll-container
                        class={[
                          "h-[calc(100vh-74px-env(safe-area-inset-top))] touch-auto",
                          open.value ? "overflow-auto" : "overflow-hidden",
                        ]}
                      >
                        <MobileSafeArea class="pb-24" top={false}>
                          <div ref={contentContainer}>{slots.default?.()}</div>
                        </MobileSafeArea>
                      </div>

                      {props.showClose && (!open.value || props.drawerDisabled) && (
                        <Button
                          variant={props.variant === "light" ? "iconLight" : "iconDark"}
                          class="absolute top-3 right-3 h-auto w-auto text-xs"
                          onClick={() => {
                            drawer.value?.close();

                            requestAnimationFrame(() => {
                              // wait for at leat one animation frame
                              props.onClose?.();
                            });
                          }}
                        >
                          <Icon name="close" />
                        </Button>
                      )}
                    </div>
                  </drawer-track>
                </MobileSafeArea>
              </div>

              {props.showTitle && (
                <div
                  class={[
                    "fixed top-0 left-0 w-full bg-[var(--color-bg)] shadow-md transition-opacity duration-300",
                    open.value
                      ? "pointer-events-auto opacity-100"
                      : "pointer-events-none opacity-0",
                  ]}
                >
                  <MobileSafeArea bottom={false}>
                    <div class="flex w-full items-center justify-between px-5 pt-5 pb-3">
                      <div class="font-bold text-base">
                        <ClientOnly>
                          {/* dont hydrate this */}
                          {detailsData.value?.title ?? ""}
                        </ClientOnly>
                      </div>

                      {!historyState.value?.back?.match(ROUTE_VIEW.PREVIEW) ? (
                        <Button
                          class={
                            open.value
                              ? "pointer-events-auto"
                              : "pointer-events-none touch-none"
                          }
                          label={t("map.navigation.back")}
                          onClick={() => {
                            props.onClose?.();

                            if (historyState.value?.back) {
                              router.back();
                            } else {
                              navigate(`/${language.value}`);
                            }
                          }}
                          variant={props.variant === "light" ? "iconLight" : "iconDark"}
                        >
                          <Icon name="arrow-left" />
                        </Button>
                      ) : (
                        <Button
                          class={
                            open.value
                              ? "pointer-events-auto"
                              : "pointer-events-none touch-none"
                          }
                          label={t("map.navigation.closePreview")}
                          onClick={() => {
                            props.onClose?.();
                            navigate({
                              query: {
                                view: ROUTE_VIEW.PREVIEW,
                              },
                            });
                          }}
                          variant={props.variant === "light" ? "iconLight" : "iconDark"}
                        >
                          <Icon name="arrow-down" />
                        </Button>
                      )}
                    </div>
                  </MobileSafeArea>
                </div>
              )}
            </>
          )}

          {/* Desktop Detail view */}
          {isDesktop && (
            <div
              ref={scrollContainer}
              class={[
                "pointer-events-auto absolute top-0 right-0 bottom-0 h-auto w-full",
                "max-w-screen overflow-auto overflow-x-hidden bg-gray shadow-md sm:w-[var(--width-detail)]",
              ]}
            >
              <div class="absolute top-5 right-5 z-10 lg:top-8">
                <Button
                  class="pointer-events-auto"
                  variant={props.variant === "light" ? "iconLight" : "iconDark"}
                  onClick={() => {
                    props.onClose?.();
                  }}
                  label={t("misc.ariaLabelClose")}
                >
                  <Icon name="close" aria-hidden />
                </Button>
              </div>

              <div class="h-full">{slots.default?.()}</div>
            </div>
          )}
        </div>
      );
    };
  },
  {
    name: "PageLayer",
    props: [
      "class",
      "drawerDisabled",
      "drawerDynamicHeight",
      "variant",
      "onOpen",
      "onClose",
      "onCollapse",
      "open",
      "showTitle",
      "showClose",
    ],
  },
);
