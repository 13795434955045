export { Style } from "@capacitor/status-bar";

import { StatusBar, type Style } from "@capacitor/status-bar";
import { onMounted, useEnv } from "#imports";

export function setStatusBar(options: {
  style: Style.Dark | Style.Light;
}) {
  const env = useEnv();

  if (env.ENVIROMENT !== "ios") {
    return;
  }

  onMounted(() => {
    if (env.PLATFORM === "native") {
      StatusBar.setStyle({ style: options.style });
    }
  });
}
